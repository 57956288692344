export type Occurrence = 'day' | 'week' | 'month';

type OccurrenceTime = {
    [K in Occurrence]: number;
};

export type FrequencyOption = {
    ampCount: number;
    occurrence: Occurrence;
};

export class AuthorRequestFrequencyFactory {
    private occurrenceTime: OccurrenceTime = {
        day: 86400,
        week: 604800,
        month: 2592000
    };

    getFrequency(ampCount: number, occurrence: Occurrence): number {
        return this.occurrenceTime[occurrence] / ampCount;
    }

    getFrequencyOptions(frequency: number): FrequencyOption {
        switch (frequency) {
            case 86400:
                return { ampCount: 1, occurrence: 'day' };

            case 604800:
                return { ampCount: 1, occurrence: 'week' };

            case 302400:
                return { ampCount: 2, occurrence: 'week' };

            case 201600:
                return { ampCount: 3, occurrence: 'week' };

            case 151200:
                return { ampCount: 4, occurrence: 'week' };

            case 120960:
                return { ampCount: 5, occurrence: 'week' };

            case 100800:
                return { ampCount: 6, occurrence: 'week' };

            case 1296000:
                return { ampCount: 2, occurrence: 'month' };

            case 864000:
                return { ampCount: 3, occurrence: 'month' };

            case 648000:
                return { ampCount: 4, occurrence: 'month' };

            case 518400:
                return { ampCount: 5, occurrence: 'month' };

            case 432000:
                return { ampCount: 6, occurrence: 'month' };

            default:
                return { ampCount: 1, occurrence: 'month' };
        }
    }
}
