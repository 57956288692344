<template>
    <v-container class="wizard__content--item-container">
        <template v-if="loading">
            <v-skeleton-loader class="mt-4" width="20em" type="heading" />

            <amp-row-first class="mt-4">
                <template #input>
                    <v-skeleton-loader type="article, actions" />
                </template>

                <template #validation>
                    <v-skeleton-loader type="paragraph" />
                </template>
            </amp-row-first>
        </template>

        <template v-if="!loading">
            <v-btn text color="primary" @click="back">
                <v-icon left small>fa-arrow-left</v-icon>
                Back
            </v-btn>

            <h2>Request Settings</h2>

            <a-form :submit="submit" class="settings-form">
                <amp-row-first>
                    <template #input>
                        <p class="font-weight-bold">
                            {{ !continueAmpGeneration ? 'Resume' : 'Pause' }}
                            Request
                        </p>

                        <p v-if="continueAmpGeneration">
                            If you need to take a break on this recurring
                            request and come back to it later, you can pause it.
                            You’ll be able to restart right where you left off.
                        </p>

                        <p v-if="continueAmpGeneration">
                            Authoring credits have already been deducted for all
                            current Amps in this request. While a request is
                            paused, Amps currently with our authoring team will
                            still be completed and returned to you for
                            publication. You are not obligated to publish the
                            Amps, and no further authoring credits will be
                            deducted while a request is paused.
                        </p>

                        <div class="d-flex align-center">
                            <p class="ma-0 mr-2 font-weight-bold">
                                Continue generating Amps in this request?
                            </p>

                            <a-toggle
                                v-model="continueAmpGeneration"
                                class="pause-switch"
                                hide-details
                                :label="requestStateLabel"
                                color="success"
                            />
                        </div>
                    </template>

                    <template #validation>
                        <company-card-small
                            :company="request.company"
                            class="company-card"
                        />
                    </template>
                </amp-row-first>

                <amp-row v-if="continueAmpGeneration">
                    <template #input>
                        <validation-provider rules="required">
                            <p class="font-weight-bold pt-4">
                                Change Frequency
                            </p>

                            <label>
                                Create a new Done For You Amp in this Request
                                every:
                            </label>

                            <v-radio-group v-model="occurrence">
                                <v-radio
                                    label="Day"
                                    value="day"
                                    :disabled="!canSelectCustomFrequency"
                                />

                                <v-radio
                                    label="Week"
                                    value="week"
                                    :disabled="!canSelectCustomFrequency"
                                />

                                <v-radio label="Month" value="month" />

                                <v-radio
                                    value="other"
                                    class="align-start align-sm-center"
                                    :disabled="!canSelectOtherFrequency"
                                >
                                    <template #label>
                                        <div
                                            class="d-flex flex-column flex-sm-row align-sm-center justify-sm-start flex-wrap flex-sm-nowrap"
                                        >
                                            <span class="mr-2 mb-2 mb-sm-0">
                                                Other
                                            </span>

                                            <a-select-input
                                                v-model="frequency.ampCount"
                                                dense
                                                :items="ampCountFrequency"
                                                hide-details
                                                class="frequency-selector"
                                            />

                                            <span
                                                class="mx-0 mx-sm-2 my-2 my-sm-0"
                                                style="width: fit-content"
                                            >
                                                Amp(s) per
                                            </span>

                                            <a-select-input
                                                v-model="frequency.occurrence"
                                                dense
                                                :items="['week', 'month']"
                                                hide-details
                                                class="frequency-selector"
                                            />
                                        </div>
                                    </template>
                                </v-radio>
                            </v-radio-group>

                            <p>
                                Changes to your Amp schedule will take effect
                                from today. Please note if you wish to run
                                simultaneous campaigns for separate companies or
                                products it is better to create separate
                                requests for each.
                            </p>
                        </validation-provider>
                    </template>

                    <template #validation>
                        <a-alert type="info" class="mt-4">
                            You have {{ credits }} authoring credits. At this
                            frequency we will deduct
                            {{ ampCountText }} authoring credit every
                            {{ recurrenceText }}.
                        </a-alert>
                    </template>
                </amp-row>

                <amp-row-last>
                    <template #input>
                        <div class="text-right pt-4">
                            <v-btn
                                type="submit"
                                x-large
                                color="primary"
                                class="mt-4 mt-sm-0"
                                :block="$vuetify.breakpoint.xsOnly"
                                :loading="submitting"
                            >
                                Confirm
                            </v-btn>
                        </div>
                    </template>
                </amp-row-last>
            </a-form>
        </template>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from '@/utils/decorators';

import type { AuthorRequest } from '@/types/AuthorRequest';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import {
    AmpRowFirst,
    AmpRowLast,
    AmpRow
} from '@/components/AmpModule/AmpPage';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';
import { CompanyCardSmall } from '@/components/CompanyCardSmall';
import { AToggle } from '@/components/AForm/Inputs/AToggle';

import {
    AuthorRequestFrequencyFactory,
    Occurrence
} from '@/components/Authoring/AuthorRequestFrequency';

@Component({
    components: {
        AForm,
        AAlert,
        AmpRowFirst,
        AmpRowLast,
        ASelectInput,
        CompanyCardSmall,
        AmpRow,
        AToggle
    }
})
export default class Settings extends Vue {
    request: Partial<AuthorRequest> = {};

    credits = 0;

    continueAmpGeneration = true;

    occurrence = 'month';

    @Watch('occurrence')
    onRecurrenceChange(occurrence: string) {
        this.setRequestFrequency(occurrence);
    }

    loading = false;

    submitting = false;

    AuthorRequestFrequency = new AuthorRequestFrequencyFactory();

    frequency: { ampCount: number; occurrence: Occurrence } = {
        ampCount: 2,
        occurrence: 'month'
    };

    @Watch('frequency', { deep: true })
    onFrequencyChange() {
        this.setRequestFrequency(this.occurrence);
    }

    frequencyOptions = null;

    get ampCountFrequency() {
        return [2, 3, 4, 5, 6];
    }

    get canSelectCustomFrequency() {
        return this.credits > 0;
    }

    get canSelectOtherFrequency() {
        return this.credits > 1;
    }

    get recurrenceText() {
        if (this.occurrence === 'other') {
            return this.frequency.occurrence;
        }

        return this.occurrence;
    }

    get ampCountText() {
        if (this.occurrence === 'other') {
            return this.frequency.ampCount;
        }

        return 1;
    }

    get requestStateLabel() {
        return this.continueAmpGeneration ? 'RESUMED' : 'PAUSED';
    }

    setRequestFrequency(occurrence: string) {
        if (this.request) {
            let frequency: number;

            if (occurrence !== 'other') {
                frequency = this.AuthorRequestFrequency.getFrequency(
                    1,
                    occurrence as Occurrence
                );
            } else {
                frequency = this.AuthorRequestFrequency.getFrequency(
                    this.frequency.ampCount,
                    this.frequency.occurrence
                );
            }

            this.request.frequency = frequency;
        }
    }

    submit() {
        let url = `/author_requests/update/${this.request.id}`;

        if (!this.continueAmpGeneration) {
            url = `${url}?pauseAmpGeneration=1`;
        } else {
            url = `${url}?pauseAmpGeneration=0`;
        }

        this.submitting = true;

        this.$http
            .post(url, this.request)
            .then(() => {
                this.$store.dispatch(
                    'notification/success',
                    'Request updated successfully'
                );

                this.$router.back();
            })
            .finally(() => {
                this.submitting = false;
            });
    }

    setupFrequencyOptions(frequency: number) {
        const options =
            this.AuthorRequestFrequency.getFrequencyOptions(frequency);

        if (options.ampCount > 1) {
            this.occurrence = 'other';

            this.frequency.ampCount = options.ampCount;

            this.frequency.occurrence = options.occurrence;
        } else {
            this.occurrence = options.occurrence;
        }
    }

    getData() {
        this.loading = true;

        this.$http
            .get(`/author_requests/view/${this.$route.params.id}`)
            .then(({ data }) => {
                const { request, credits } = data.data;

                this.request = request;

                this.continueAmpGeneration = request.paused_at === null;

                this.credits = credits;

                this.setupFrequencyOptions(request.frequency);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    mounted() {
        this.getData();
    }

    back() {
        this.$router.back();
    }
}
</script>

<style lang="scss">
.wizard__content--item-container {
    .settings-form {
        margin-top: 1em;

        .company-card {
            margin-top: -1.5em;
        }

        .pause-switch {
            margin: 0;

            &.v-input--is-label-active {
                .v-input--switch__track,
                .v-input--switch__thumb {
                    color: $dark-orange;
                    caret-color: $dark-orange;
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .wizard__content--item-container {
        .company-card {
            margin-top: 0 !important;
        }
    }
}
</style>
